// Filename - pages/LandingPage.js

import React, { useState, useEffect, useRef, useCallback } from "react";
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

// Styled Components
const Button = styled.button`
  border: none;
  color: #606060;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition: background-color 0.4s, color 0.4s;
  cursor: pointer;
  margin: 0 10px;
  
  &:hover {
    background-color: white;
    color: #606060;
  }
`;

const AgreeButton = styled.button`
  background-color: #4CAF50;
  border: 2px solid #4CAF50;
  border-radius: 4px;
  color: white;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s, transform 0.3s, border-color 0.3s;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: white;
    color: #4CAF50;
    border-color: #4CAF50;
    transform: translateY(-2px);
  }

  &:active {
    background-color: #45a049;
    color: white;
    border-color: #45a049;
    transform: translateY(0);
  }
`;

const BackButton = styled(Button)`
  background-color: #F0554E;
`;

const RejectButton = styled.button`
  background-color: #FE6174;
  border: 2px solid #FE6174;
  border-radius: 4px;
  color: white;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s, transform 0.3s, border-color 0.3s;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: white;
    color: #FE6174;
    border-color: #FE6174;
    transform: translateY(-2px);
  }

  &:active {
    background-color: #45a049;
    color: white;
    border-color: #45a049;
    transform: translateY(0);
  }
`;

const ModalHeader = styled.div`
  width: 95%;
  font-size: 26px;
  font-weight: 600;
  color: #606060;
  text-align: center;
  padding: 20px;
  background-color: #F7F8F8;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #606060;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px;
`;

const Landing = () => {
  const [open, setOpen] = useState(true);
  const [rejectOpen, setRejectOpen] = useState(false);
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const sessionStorageInitializedRef = useRef(false); 
  const hasNavigatedRef = useRef(false);
  const [isRedirecting, setIsRedirecting] = useState(false);

  const handleAgree = useCallback(async () => {
    if (isRedirecting && localStorage.getItem('authenticated')) return; // Prevent multiple redirects
    setIsRedirecting(true); // Set redirect flag
    
    console.log('Agree button clicked');
    try {
      localStorage.setItem('authenticated',true)
      await loginWithRedirect(); // Await the redirection
    } finally {
      setIsRedirecting(false); // Reset redirect flag
    }
    // if (localStorage.getItem('authenticated')){
    //   setTimeout(() => {
    //     navigate('/capture');
    //   }, 100000); 
    // }
  });

  const handleLogin = useCallback(async () => {
    if (isRedirecting && localStorage.getItem('authenticated')) return; // Prevent multiple redirects
    setIsRedirecting(true); // Set redirect flag
    
    console.log('Agree button clicked');
    try {
      localStorage.setItem('authenticated',true)
      await loginWithRedirect(); // Await the redirection
    } finally {
      setIsRedirecting(false); // Reset redirect flag
    }
    if (localStorage.getItem('authenticated')){
        navigate('/capture');
    }
  });

  const checkOrigin = () => {
    // const existingFid = localStorage.getItem('fid')
    const params = new URLSearchParams(window.location.search);
    console.log("Origin URL: ",window.location.search)
    console.log(">>",localStorage.getItem('authenticated'),localStorage.getItem('fidid'))
    if (localStorage.getItem('authenticated') && !localStorage.getItem('fidid') === null){
      console.log("returning old value")
      return localStorage.getItem('fidid')
    }
    for (const value of params.values()) {
      // if (value.includes('cloudfront')) {
      //   localStorage.setItem('fidid','aqilliz')
      //   return "aqilliz";
        
      // }
      if (value.includes('treasure') || localStorage.getItem('fidid') === 'treasurepack') {
        localStorage.setItem('fidid','treasurepack')
        return "treasurepack";
        }
      }
      // if (localStorage.getItem('fidid') === 'fid1'){
        // return localStorage.getItem('fidid')
      // }else{
        console.log("returning fid1")
        localStorage.setItem('fidid','fid1')
        return "fid1";
      // }
      // return localStorage.getItem('fidid')
      // }
      
  };
  useEffect(() => {
	if (!sessionStorageInitializedRef.current) {
		const origin = checkOrigin();
        window.fiduciaryId = origin;
        localStorage.setItem('fiduciaryId', origin);
        sessionStorageInitializedRef.current = true;
        // Call handleAgree if the origin is aqilliz
        // if (origin === 'aqilliz') {
        //   navigate('/capture')
        // }
	}
  }, []);
  
  // console.log("landing page - fidID",checkOrigin())
  
  // useEffect(() => {
  //   if (isAuthenticated && !hasNavigatedRef.current) {
  //     console.log('coming inside', isAuthenticated);
	// 	hasNavigatedRef.current = true; 
  //     navigate('/capture');
  //   }
  // }, [isAuthenticated]);
  return (
    <div>
      <Popup
        contentStyle={{ width: '600px', padding: '20px', borderRadius: '10px', boxShadow: '0 0 10px rgba(0,0,0,0.2)' }}
        open={open}
		closeOnDocumentClick={false} 
        onClose={() => setOpen(false)}
        modal
      >
        <div className="modal">
          <ModalHeader>User Consent</ModalHeader>
          <ModalContent>
            <p>We are committed to offering you authentic content at this destination with relevant advertisements. You will have full control over your data and you can opt yourselves out of it any time. In the meanwhile, we will be sharing your preferences with our partners that will not affect your overall experience here.</p>
            <p>By clicking "Accept," you consent to the following:</p>
            <ul style={{display:"flex", flexDirection:"column", alignItems:'flex-start'}}>
              <li>Device identification through characteristic scanning</li>
              <li>Storage and access of information on your device</li>
              <li>Delivery of personalized ads and content</li>
            </ul>
          </ModalContent>
          <ModalActions>
            <AgreeButton onClick={handleAgree}>Agree</AgreeButton>
            <RejectButton onClick={() => {
               setOpen(false); // Close the first popup
              setRejectOpen(true);
            }}>Reject</RejectButton>
          </ModalActions>
        </div>
      </Popup>

      <Popup
        contentStyle={{ width: '350px', padding: '20px', borderRadius: '10px', boxShadow: '0 0 10px rgba(0,0,0,0.2)' }}
        open={rejectOpen}
		closeOnDocumentClick={false}
        modal
      >
        <div className="modal">
          <ModalHeader>Attention!</ModalHeader>
          <ModalContent>
          Thank you! We value your preference. You will only be able to access some selected features. We do hope you like it.
          </ModalContent>
          <ModalActions>
            <BackButton onClick={() => {
              setOpen(true); // Optionally reopen the first popup
			  setRejectOpen(false); // Close the second popup
            }}>Try Again</BackButton>
          </ModalActions>
        </div>
      </Popup>
    </div>
  );
};

export default Landing;
