import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  position: fixed;
  z-index: -1;
  bottom: 0.5rem;
  transform: translate(-50%, 0);
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  font-size: 0.8rem;
  color: gray;
`;



const Footer = ({fullYear}) => (
  <StyledDiv>© {fullYear} Aqilliz Pte. Ltd. All rights reserved</StyledDiv>
);

export default Footer;
