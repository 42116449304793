import React from "react";
import { Nav, NavLink, NavMenu } from "./navbarElement";
import onamLogo from './Onam-Logo.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

const Navbar = () => {
	const navigate = useNavigate();
	const location = useLocation();
  const {  logout } = useAuth0();
	const handleLogout = () => {
		localStorage.removeItem('fidid')
		localStorage.removeItem('authenticated')
    logout({ logoutParams: { returnTo: 'https://d3lihvilg2j9z8.cloudfront.net/dashboard'} })
    // xnavigate('/');
	};
	
	const hasCookies = () => {
		return document.cookie.split(";").some((item) => item.trim().startsWith("your_cookie_name="));
	};

	const isRootUrl = location.pathname === '/dashboard' ;
	
	const displayButton = localStorage.getItem('authenticated') && isRootUrl === true
	return (
		<>
			<Nav style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<NavMenu>
				
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								fontSize: '40px',
							}}
						>
							<img
								src={onamLogo}
								alt="Aqilliz Logo"
								style={{
									width: '9.5rem',
									fontSize: '40px',
									marginRight: '1rem',
								}}
							/>
						</div>
						Onam Pvt.Ltd
				</NavMenu>
				{displayButton && (
					<button
						onClick={handleLogout}
						style={{
							background: '#4CAF50',
							border: 'none',
							color: 'white',
							fontSize: '18px',
							padding: '10px 20px',
							borderRadius: '4px',
							cursor: 'pointer',
							marginRight: '20px',
							boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
							transition: 'background-color 0.3s, transform 0.3s',
						}}
						onMouseOver={(e) => (e.target.style.backgroundColor = '#45a049')}
						onMouseOut={(e) => (e.target.style.backgroundColor = '#4CAF50')}
						onMouseDown={(e) => (e.target.style.transform = 'translateY(2px)')}
						onMouseUp={(e) => (e.target.style.transform = 'translateY(0)')}
					>
						Logout
					</button>
				)}
			</Nav>
		</>
	);
};

export default Navbar;
