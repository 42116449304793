import React, { useState } from 'react';
import styled from 'styled-components';

const CollapsibleContainer = styled.div`
  display: inline-block; /* Allows the width to adjust based on content */
  background-color: #f7f7f7;
  border-radius: 12px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 1rem;
  overflow: hidden;
`;

const TitleContainer = styled.div`
  padding: 1rem;
  cursor: pointer;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
`;

const ContentContainer = styled.div`
  padding: 1rem;
  border-top: 1px solid #e0e0e0;
`;


const Collapsible = ({ title, children, open = false }) => {
  const [isOpen, setIsOpen] = useState(open);

  return (
    <CollapsibleContainer>
      <TitleContainer onClick={() => setIsOpen(!isOpen)}>
        {title}
      </TitleContainer>
      {isOpen && (
        <ContentContainer>
          {children}
        </ContentContainer>
      )}
    </CollapsibleContainer>
  );
};

export default Collapsible;
