// Filename - pages/about.js

import React, { useState, useEffect } from "react";
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
// Styled Components
const MAPPING = {
  'Consent0':'Device identification through characteristic scanning',
  'Consent1':'Storage and access of information on your device',
  'Consent2':'Delivery of personalized ads and content'

};
const DashboardButton = styled.button`
  background-color: #FE6174; /* Green */
  border: 2px solid #FE6174; /* Same color as background */
  border-radius: 4px;
  color: white;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s, transform 0.3s, border-color 0.3s;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: white;
    color: #FE6174;
    border-color: #FE6174; /* Border color changes on hover */
    transform: translateY(-2px);
  }

  &:active {
    background-color: #45a049;
    color: white;
    border-color: #45a049; /* Border color changes on active */
    transform: translateY(0);
  }
`;

const AgreeButton = styled.button`
  background-color: #4CAF50; /* Green */
  border: 2px solid #4CAF50; /* Same color as background */
  border-radius: 4px;
  color: white;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s, transform 0.3s, border-color 0.3s;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: white;
    color: #4CAF50;
    border-color: #4CAF50; /* Border color changes on hover */
    transform: translateY(-2px);
  }

  &:active {
    background-color: #45a049;
    color: white;
    border-color: #45a049; /* Border color changes on active */
    transform: translateY(0);
  }
`;

const ModalHeader = styled.div`
  width: %;
  font-size: 26px;
  font-weight: 600;
  color: #606060;
  text-align: center;
  padding: 20px;
  background-color: #F7F8F8;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #606060;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.8;
  margin-bottom: 20px;
  
  ul {
    list-style: none;
    padding: 0;
  }

  li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  input[type="checkbox"] {
    margin-right: 10px;
  }
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px;
`;

// Capture Component
const Capture = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  
  if (!localStorage.getItem('authenticated')){
    localStorage.removeItem('fidid')
    localStorage.setItem('fidid','aqilliz')
    localStorage.setItem('authenticated',true)
    loginWithRedirect({
      redirect_uri: window.location.origin + '/capture'
    });
  }
  console.log('authenticated in capture',localStorage.getItem('authenticated'))
  if (localStorage.getItem('authenticated')){
    const params = new URLSearchParams(window.location.search);
    console.log("Origin URL in capture: ",window.location.search)
    
    for (const value of params.values()) {
      if (value.includes('cloudfront')){
        localStorage.removeItem('fidid')
        localStorage.setItem('fidid','aqilliz')
      }
    }
  }
  console.log('fidid in capture',localStorage.getItem('fidid'))
  const {
    user,
  } = useAuth0();
  const [consents, setConsents] = useState({ consent1: false, consent2: false });
  const [open, setOpen] = useState(true);
  const [apiCalled, setApiCalled] = useState(false);
  const closeModal = () => setOpen(false);
//   const fid = 'fid1' //logic to implement dynamic fid
var fid = localStorage.getItem('fidid');
console.log('Fiduciary Id is:', fid); 
  const navigate = useNavigate();
  // const newBucket = () => navigate('/dashboard');



  // const [viewUser, setViewUser] = useState([]);

  const [viewUserResult, setViewUserResult] = useState([]);
  const [viewConsent, setViewConsent] = useState([]);

// user object have auth details. so user must have some value
  // useEffect(() => {
  //   if (viewUser.length === 0 && user !== undefined) getUser();
  // }, []);
  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setConsents((prevConsents) => ({ ...prevConsents, [id]: checked }));
  };

  // const getUser = () => {
  //   axios.get(`https://consent-mgmt-svc.api.staging.aqilliz.com/consent-manager/principal/username/${user.email}`, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Accept': 'application/json',
  //     },
  //   })
  //     .then(res => setViewUser(res))
  //     .catch(err => console.error(err));
  // };

  useEffect(() => {
    if (viewUserResult.length === 0 && user?.email && !apiCalled){
      console.log("coming inside")
      setApiCalled(true);
      CreatePrincipal();
      getExistingConsent(fid)
    } 
  }, [user?.email]);



  console.log('viewUserResult', viewUserResult);
  const CreatePrincipal = async () => {
    console.log('coming in view principal',viewUserResult);
    setViewUserResult([1,2,3,4]);
    const data = { userName: user.email };
    const headers = {
       'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
    };
    await axios.post('https://consent-mgmt-svc.api.staging.aqilliz.com/consent-manager/principal/register/verify-create', data, { headers })
      .then(response => {
        console.log("Status: ", response.status);
        console.log("Data: ", response.data);
        setViewUserResult([1,2,3,4]);
      }).catch(error => {
        console.error('Something went wrong!', error);
      });
  };

  const getExistingConsent = (fiduciaryId) => {
    console.log('calling getExistingConsent,fiduciaryId ')
    axios.get(`https://consent-mgmt-svc.api.staging.aqilliz.com/consent-manager/principal/${user?.email}-principal@consentmanager.in/fiduciary/fd-${fiduciaryId}@consentmanager.in/consent`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    })
      .then(res => {
        setViewConsent(res.data);
        // setLoading(false); // Stop loading after data is fetched
      })
      .catch(err => {
        console.error(err);
        // setLoading(false); // Stop loading even on error
      });
  };
  var existingConsent = ""
  if(viewConsent.length !== 0){
    console.log("Existing consents",viewConsent[0].consents)
    existingConsent = viewConsent[0].consents
  }else{
    existingConsent = "Consent0, Consent2, Consent1"
  }
  const consentList = ["Consent0", "Consent1", "Consent2"];
  // const existingConsent = "Consent0, Consent2"
  const isChecked = (consent) => existingConsent.includes(consent);
  const newBucket = async () => {
    var consentTypes = [];
    if(viewConsent.length !== 0){
      consentTypes = existingConsent.split(",")
    }else{
    consentTypes.push("Consent0");
    consentTypes.push("Consent1");
    consentTypes.push("Consent2");
    }
    const apiData = {
      consents: [
        {
          piiData: user?.email,
          consentTypes,
          revokable: true,
        }
      ],
    };
  
    try {
      // First API call
      const response = await axios.post(`https://consent-mgmt-svc.api.staging.aqilliz.com/consent-manager/fiduciary/fd-${fid}@consentmanager.in/principal/${user?.email}-principal@consentmanager.in/consent/update`, {
        piiData: user?.email,
        consentTypes,
      });
      console.log('First API Response:', response.data===0);
      // If the first API call is successful
      if (response && response.data === 0) {
        try {
          const newBucketResponse = await axios.post(`https://consent-mgmt-svc.api.staging.aqilliz.com/consent-manager/fiduciary/fd-${fid}@consentmanager.in/principal/${user?.email}-principal@consentmanager.in/consent`, apiData, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
          console.log('New Bucket API Response:', newBucketResponse.data);
          navigate('/dashboard');
        } catch (newBucketError) {
          console.error('Error in New Bucket API call:', newBucketError);
        }
      }else{
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error in first API call:', error);
  
      // If the first API call fails, call the newBucket API
      try {
        const newBucketResponse = await axios.post(`https://consent-mgmt-svc.api.staging.aqilliz.com/consent-manager/fiduciary/fd-${fid}@consentmanager.in/principal/${user?.email}-principal@consentmanager.in/consent`, apiData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log('New Bucket API Response:', newBucketResponse.data);
        navigate('/dashboard');
      } catch (newBucketError) {
        console.error('Error in New Bucket API call:', newBucketError);
      }
    }
  };
  


  return (
    <div>
      <Popup
        contentStyle={{ width: '750px', padding: '25px', borderRadius: '10px', boxShadow: '0 0 10px rgba(0,0,0,0.2)' }}
        open={true}
		closeOnDocumentClick={false} 
        modal
      >
        <div className="modal">
          <ModalHeader>Consent Provided</ModalHeader>
          <ModalContent>
            <p>Thank you for your consent.</p>
            <p>PII data stored with us currently: {user ? user.email : ''}.</p>
            <p>We, together with our partners, store and access information on your device to process personal data. These preferences will be shared with our partners and will not impact your browsing experience. You can manage your preferences or object to data processing based on legitimate interest by clicking below.</p>
            {/* <ul>
              <li>
              <input type="checkbox" id="consent0" style={{pointerEvents:'none'}} checked={true}/>
              <label htmlFor="consent0">Device identification through characteristic scanning</label>
              </li>
              <li>
                <input
                  type="checkbox"
                  id="consent1"
				  style={{pointerEvents:'none'}}
				  checked={true}
                //   checked={consents.consent1}
                //   onChange={handleCheckboxChange}
                />
                <label htmlFor="consent1">Storage and access of information on your device</label>
              </li>
              <li>
                <input
                  type="checkbox"
                  id="consent2"
				  style={{pointerEvents:'none'}}
				  checked={true}
                //   checked={consents.consent2}
                //   onChange={handleCheckboxChange}
                />
                <label htmlFor="consent2">Delivery of personalized ads and content</label>
              </li>
            </ul> */}
            <ul>
              {consentList.map((consent, index) => (
                <li key={index}>
                  <input
                    type="checkbox"
                    id={`consent${index}`}
                    style={{ pointerEvents: 'none' }}
                    checked={isChecked(`Consent${index}`)}
                  />
                  <label htmlFor={`consent${index}`}>
                    { MAPPING[consent]}
                  </label>
                </li>
              ))}
            </ul>
          </ModalContent>
          <ModalActions>
            {/* <AgreeButton onClick={handleAgree}>Agree</AgreeButton> */}
            <DashboardButton onClick={newBucket}>Manage Consent</DashboardButton>
          </ModalActions>
        </div>
      </Popup>
    </div>
  );
};

export default Capture;
