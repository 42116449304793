// Filename - App.js

import React from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Navbar/footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "./pages/LandingPage";
import Capture from "./pages/ContentCapturePage";
import Dashboard from "./pages/DashboardPage";
import { Auth0Provider } from "@auth0/auth0-react";

function App() {
  return (
    <Auth0Provider
      domain="aqilliz-dev.us.auth0.com"
      clientId="M0OMUk4I4mgvyakTqlmV7iYaZlkFVwgl"
      redirectUri={window.location.origin+'/capture'}
    >
      <Router>
        <Navbar />
        <Footer />
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route path="/capture" element={<Capture />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
      </Router>
    </Auth0Provider>
  );
}

export default App;
